<template>
  <a-modal
    title="新建信用证详情"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok', this.date) }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="信用证编号">
              <a-input v-decorator="['number', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="授信银行">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                label-in-value
                allow-clear
                v-decorator="[
                  'bank',
                  {
                    rules: [
                      { required: true, message: '必填项，请填写信息' },
                    ],
                  },
                ]"
                placeholder="请输入银行"
                :default-active-first-option="false"
                @search="this.bankSearch"
                @change="handleBankChange"
              >
                <a-select-option v-for="d in bankList" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="信用证货币金额">
              <a-input-number :precision="2" style="width: 50%" v-decorator="['amount', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="人民币金额">
              <a-input-number :precision="2" style="width: 50%" v-decorator="['amount_cny', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="信用证货币到人民币的汇率">
              <a-input-number :precision="2" style="width: 50%" v-decorator="['to_cny_rate', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="融资利率" v-if="false">
              <a-input-number :precision="2" style="width: 50%" v-decorator="['financing_rate', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="类别">
              <a-input v-decorator="['type', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
              <span>即期信用证、即期押汇、远期（+天数）信用证</span>
            </a-form-item>
            <a-form-item label="费用">
              <a-input-number :precision="2" style="width: 50%" v-decorator="['fee', {rules: [{required: true, message: '必填项，请填写信息'}]}]" /><br/>
              <span>开证费、承兑费、电报费</span>
            </a-form-item>
            <a-form-item label="额度占用">
              <a-input-number :precision="2" style="width: 50%" v-decorator="['limit', {rules: [{required: true, message: '必填项，请填写信息'}]}]" /><br/>
              <span>仅指敞口金额，不包含保证金</span>
            </a-form-item>
            <a-form-item label="开证时间">
              <a-date-picker @change="onDateChange" v-decorator="['start_date', {rules: [{required: true, message: '必填项，请填写信息'}]}]"/>
            </a-form-item>
            <a-form-item label="起止时间" v-if="false">
              <a-range-picker @change="onDateChange" v-decorator="['start_end_date', {rules: [{required: true, message: '必填项，请填写信息'}]}]"/>
            </a-form-item>
            <a-form-item label="备注">
              <a-textarea :rows="4" v-decorator="['remark', {rules: [{required: false, message: '必填项，请填写信息'}, {max:256, message: '输入超出长度限制'}]}]" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import moment from 'moment'
import pick from 'lodash.pick'
import debounce from 'lodash/debounce'
import { bank_list } from '@/api/trade_bank'

// 表单字段
const fields = ['id', 'name', 'contact', 'phone', 'mail']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    this.bankSearch = debounce(this.handleBankSearch, 800)
    return {
      form: this.$form.createForm(this),
      bankList: [],
      date: {
        start_date: ''
      }
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
    this.handleBankSearch(undefined)
  },
  methods: {
    moment,
    handleBankSearch (value) {
      bank_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.bankList = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    handleBankChange (value) {
      console.log(value)
      if (value === undefined) {
        this.handleBankSearch(value)
      }
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    onDateChange (date, dateString) {
      // console.log(date, dateString)
      this.date.start_date = dateString
      // this.date.end_date = dateString[1]
    }
  }
}
</script>
