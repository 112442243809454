import request from '@/utils/request'

const creditLetterApi = {
  trade_credit_letter_list: '/trade/credit_letter/',
  trade_credit_letter_create: '/trade/credit_letter/',
  trade_credit_letter_update: '/trade/credit_letter/',
  trade_credit_letter_delete: '/trade/credit_letter/',
  trade_credit_letter_archived: '/trade/credit_letter_archived/'
}

/**
 * 列表
 */
export function credit_letter_list (parameter) {
  return request({
    url: creditLetterApi.trade_credit_letter_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function credit_letter_create (parameter) {
  return request({
    url: creditLetterApi.trade_credit_letter_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function credit_letter_update (parameter, corporation_id) {
  return request({
    url: creditLetterApi.trade_credit_letter_update + corporation_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function credit_letter_delete (corporation_id) {
  return request({
    url: creditLetterApi.trade_credit_letter_delete + corporation_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function credit_letter_archived (parameter) {
  return request({
    url: creditLetterApi.trade_credit_letter_archived,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
